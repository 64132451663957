<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    loading: true,
    data: []
  }),
  watch: {
    show (v) {
      if (v) {
        this.PLAN_GET(this.selected.id)
      }
    }
  },
  methods: {
    PLAN_GET (master) {
      this.data = []
      this.loading = true
      this.$store.dispatch('logistic/SHIPPING_PLAN_GET', {
        master: master,
        q: ''
      }).then((res) => {
        this.loading = false
        if (res.status) {
          this.data = res.data.data || []
        }
      })
    }
  }
}
</script>

<template>
  <v-dialog
    :value="show"
    persistent
    scrollable
    max-width="450"
  >
    <v-card v-if="loading" class="pa-8">
      Loading
    </v-card>
    <v-card v-else-if="selected" class="px-0">
      <v-card-title class="bg-business-secondary flex-column body-1 align-center text-uppercase font-weight-bold">
        <span class="body-2 grey--text text--lighten-4">- LACAK KIRIMAN -</span>
        <div class="white--text">
          {{ selected.ttk }}
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4 pr-2 pl-16 pr-4" style="max-height: 90vh;">
        <div v-for="(dp, iDp) in data" :key="'route-track-' + iDp" class="p-relative">
            <v-card v-if="(dp.recipient_imgs && dp.recipient_imgs !== '[]') || (dp.manifest_arrival && parseInt(dp.status_plan) !== 3)" :class="[iDp === 0 && !dp.courier_detail ? 'px-5' : 'px-0']" flat :dark="(iDp === 0 && !dp.courier_detail) ? true : false" class="d-flex flex-column justify-center fill-width rounded-lg p-relative" style="min-height: 82px;">
              {{ $localDT(dp.manifest_arrival, 'daydatetime') }}
              <div v-if="dp.recipient_imgs && parseInt(dp.status)">
                DELIVERED TO <span class="text-uppercase">{{ dp.recipient_relation || '-' }} ({{ dp.recipient_name || '#' }})</span>
                <div class="d-flex align-center">
                  <v-icon class="mr-1" small>
                    mdi-note-multiple-outline
                  </v-icon>
                  {{ dp.recipient_notes || '#' }}
                </div>
                <div v-if="dp.recipient_imgs" class="d-flex fill-width flex-wrap">
                  <img
                    v-for="(img, iimg) in $StrToArray(dp.recipient_imgs)"
                    :key="'img-delivery-' + iimg"
                    :src="img"
                    style="height: 80px; width: 80px;"
                    class="ma-1"
                    @click.prevent="OpenBrowser(img)"
                  >
                </div>
                <div class="py-1">
                  <span>Sign :</span>
                  <img
                    v-if="dp.recipient_sign"
                    :src="dp.recipient_sign"
                    style="height: 80px; width: 80px;"
                    class="ma-1 d-block"
                    @click.prevent="OpenBrowser(dp.recipient_sign)"
                  >
                </div>
              </div>
              <div v-else-if="!dp.courier_detail" class="text-uppercase">
                {{ dp.store_destination_city_name || dp.store_destination_name ? ((dp.store_destination_city_name || dp.store_destination_name) + ', ') : '' }} MANIFEST IN
                <!-- {{ dp.store_destination_name }} MANIFEST IN, {{ dp.store_destination_name }}{{ (dp.vendor_code ? (' (' + (dp.vendor_code || '-') + ')') : '') }} -->
              </div>
              <span v-if="iDp !== 0" style="display:block;position: absolute;top:-24%;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50;z-index:1;" />
              <span :class="[(iDp !== 0 || dp.courier_detail) ? 'green' : 'primary']" style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center rounded-pill">
                <v-icon color="white" small>
                  {{ iDp !== 0 || dp.courier_detail ? 'mdi-check' : 'mdi-map-marker' }}
                </v-icon>
              </span>
            </v-card>
          <v-card v-if="dp.manifest_departure" flat class="d-flex flex-column justify-center fill-width rounded-lg px-0 p-relative" style="min-height: 82px;">
            <!-- {{ $localDT(dp.manifest_departure, 'daydatetime') }} -->
            <div v-if="dp.courier_detail">
              PROCESS DELIVERY WITH COURIER
              <div class="caption">
                {{ dp.courier_detail || '-' }}
              </div>
            </div>
            <div v-else class="text-uppercase">
              {{ dp.store_origin_city_name }}, MANIFEST OUT
            </div>
            <span v-if="dp.manifest_arrival && parseInt(dp.status_plan) !== 3" style="display:block;position: absolute;top:-24%;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50;z-index:1;" />
            <span :class="[!dp.manifest_arrival ? 'bg-business-primary' : 'bg-business-secondary']" style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center rounded-pill">
              <v-icon color="white" small>
                {{ !dp.manifest_arrival ? 'mdi-map-marker' : 'mdi-check' }}
              </v-icon>
            </span>
          </v-card>
          <v-card flat class="d-flex flex-column justify-center fill-width rounded-lg px-0 p-relative" style="min-height: 82px;">
            <!-- {{ $localDT(dp.created, 'daydatetime') }} -->
            <div class="text-uppercase">
              <div v-if="dp.notes && ((dp.notes || 'X').split('[CANCELED]').length >= 2)" class="d-flex align-center red--text caption text-uppercase">
                <v-icon class="mr-2" small color="red">
                  mdi-arrow-u-left-bottom
                </v-icon>
                {{ dp.notes || '-' }}
              </div>
              {{ dp.store_origin_city_name || dp.store_origin_name }}, {{ dp.courier_detail ? 'DISPATCH COURIER' : 'MANIFESTED' }}
            </div>
            <span v-if="dp.manifest_departure" style="display:block;position: absolute;top:-24%;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50 ;z-index:1;" />
            <span :class="[!dp.manifest_departure ? 'bg-business-primary' : 'bg-business-secondary']" style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center rounded-pill">
              <v-icon color="white" small>
                {{ !dp.manifest_departure ? 'mdi-map-marker' : 'mdi-check' }}
              </v-icon>
            </span>
          </v-card>
        </div>
        <div>
          <v-card v-if="!parseInt(selected.pickup) || (parseInt(selected.pickup) && parseInt(selected.pickup_handling) && selected.pickup_end)" flat class="d-flex flex-column justify-center fill-width rounded-lg px-0 p-relative" style="min-height: 82px;">
            <!-- {{ parseInt(selected.pickup) ? $localDT(selected.pickup_end, 'daydatetime') : $localDT(selected.created, 'daydatetime') }} -->
            <div class="text-uppercase">
              <div v-if="selected.notes && ((selected.notes || 'X').split('[CANCELED]').length >= 2)" class="d-flex align-center red--text caption text-uppercase">
                <v-icon class="mr-2" small color="red">
                  mdi-arrow-u-left-bottom
                </v-icon>
                {{ selected.notes || '-' }}
              </div>
              REGISTERED AT {{ selected.shipping_origin_city_name }} {{ selected.shipping_origin_city_type }}
            </div>
            <span v-if="data.length" style="display:block;position: absolute;top:-24%;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50 ;z-index:1;" />
            <span :class="[data.length ? 'bg-business-primary' : 'bg-business-secondary']" style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center green rounded-pill">
              <v-icon color="white" small>
                mdi-package-variant-closed
              </v-icon>
            </span>
          </v-card>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="$emit('close', true)"
        >
          Close
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
